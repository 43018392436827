<template>
  <div class="FormWrap">
    <div class="tabs">
      <div
        class="collection"
        :class="activeName == 'first' ? 'active' : ''"
        @click="firstClick"
      >
        期初应收
      </div>
      <div
        class="pay"
        :class="activeName == 'second' ? 'active' : ''"
        @click="secondClick"
      >
        期初应付
      </div>
    </div>
    <div style="margin-bottom: 15px">
      <el-input
        v-model="param"
        placeholder="请输入供应商名称/联系人/联系电话"
        style="width: 300px"
        clearable
      ></el-input>
      <el-button type="primary" style="margin-left: 10px" @click="searchForm"
        >查询</el-button
      >
    </div>
    <div v-if="activeName == 'first'">
      <el-table :data="tableData" style="width: 100%" border>
        <el-table-column label="操作" width="180" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="beginSet(scope.row)"
              >期初设置</el-button
            >
          </template>
        </el-table-column>
        <el-table-column prop="code" label="编号" width="180" align="center">
        </el-table-column>
        <el-table-column
          prop="name"
          label="供应商名称"
          width="180"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="contact"
          label="联系人"
          width="180"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="phone"
          label="联系电话"
          width="180"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="initialPayment"
          label="期初应收款"
          width="180"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="remark" label="备注"> </el-table-column>
      </el-table>
    </div>
    <div v-if="activeName == 'second'">
      <el-table :data="tableData" style="width: 100%" border>
        <el-table-column label="操作" width="180" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="beginSet(scope.row)"
              >期初设置</el-button
            >
          </template>
        </el-table-column>
        <el-table-column prop="code" label="编号" width="180" align="center">
        </el-table-column>
        <el-table-column
          prop="name"
          label="供应商名称"
          width="180"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="contact"
          label="联系人"
          width="180"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="phone"
          label="联系电话"
          width="180"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="initialPayment"
          label="期初应付款"
          width="180"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="remark" label="备注"> </el-table-column>
      </el-table>
    </div>
    <el-pagination
      class="pagination"
      @size-change="changePageSize"
      @current-change="changeCurrentPage"
      :current-page="pageNum"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <el-dialog
      :title="dialogLabel"
      :visible.sync="dialogFormVisible"
      width="600px"
    >
      <div class="F">
        <div style="width: 88px; text-align: right; margin: 0 14px 20px 0">
          供应商名称:
        </div>
        <div>{{ form.name }}</div>
      </div>
      <div class="F">
        <div style="width: 88px; text-align: right; margin: 0 14px 20px 0">
          联系电话:
        </div>
        <div>{{ form.phone }}</div>
      </div>
      <el-form :model="form" ref="form" :rules="rules">
        <el-form-item
          :label="dialogLabel"
          label-width="100px"
          prop="initialPayment"
        >
          <el-input-number
            size="mini"
            v-model="form.initialPayment"
            :controls="false"
            :min="0"
            :max="99999"
            :precision="2"
            placeholder="请录入"
          ></el-input-number>
          <!-- <el-input
            v-model="form.initialPayment"
            autocomplete="off"
            style="width: 450px"
          ></el-input> -->
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="save">保 存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getCus, getSup, setCus, setSup } from "@/api/system";
export default {
  data() {
    return {
      param: "",
      pageNum: 1,
      pageSize: 20,
      total: 0,
      dialogLabel: "期初应收款",
      dialogFormVisible: false,
      form: {
        id: "",
        name: "",
        type: "",
        remark: "",
        initialPayment: "",
      },
      activeName: "first",
      tableData: [],
      rules: {
        initialPayment: [
          { required: true, message: "期初应收款不能为空", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getCus();
  },
  methods: {
    //保存
    async save() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          let params = {
            id: this.form.id,
            initialPayment: this.form.initialPayment,
          };
          let url = "";
          if (this.activeName == "first") {
            url = setCus;
          } else {
            url = setSup;
          }
          let res = await url(params);
          if (res.code == 1) {
            this.dialogFormVisible = false;
            this.$message({
              type: "success",
              message: res.msg,
            });
            this.getCus();
          } else {
            this.$message({
              type: "warning",
              message: res.msg,
            });
          }
        }
      });
    },
    // 获取应收列表
    async getCus() {
      let params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        param: this.param,
      };
      let res = await getCus(params);
      if (res.code == 1) {
        this.tableData = res.data.records;
        this.total = res.data.total;
      }
    },
    //获取应付列表
    async getSup() {
      let params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        param: this.param,
      };
      let res = await getSup(params);
      if (res.code == 1) {
        this.tableData = res.data.records;
        this.total = res.data.total;
      }
    },
    //查询
    searchForm() {
      if (this.activeName == "first") {
        this.getCus();
      } else {
        this.getSup();
      }
    },
    changePageSize(val) {
      this.pageSize = val;
      this.pageNum = 1;
      //   this.getData();
    },
    changeCurrentPage(val) {
      this.pageNum = val;
      //   this.getData();
    },
    beginSet(row) {
      this.form = { ...row };
      if (this.activeName == "first") {
        this.form.type = "客户";
      } else {
        this.form.type = "供应商";
      }
      this.dialogFormVisible = true;
    },
    firstClick() {
      this.activeName = "first";
      this.dialogLabel = "期初应收款";
      this.pageNum = 1;
      this.pageSize = 20;
      this.param = "";
      this.getCus();
    },
    secondClick() {
      this.activeName = "second";
      this.dialogLabel = "期初应付款";
      this.pageNum = 1;
      this.pageSize = 20;
      this.param = "";
      this.getSup();
    },
  },
};
</script>

<style lang="scss" scoped>
.tabs {
  width: 100%;
  height: 50px;
  line-height: 50px;
  font-size: 14px;
  display: flex;
  justify-content: start;
  border-bottom: 1px solid #ccc;
  //   padding-left: 20px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 15px;
  .collection {
    width: 80px;
    height: 50px;
    line-height: 50px;
    margin-right: 20px;
  }
  .pay {
    width: 80px;
    height: 50px;
    line-height: 50px;
  }
}
.active {
  border-bottom: 2px solid #1e80ff;
  color: #1e80ff;
  transition: 0.5s;
}
.F {
  display: flex;
}
.pagination {
  width: 100%;
  text-align: center;
  margin-top: 30px;
}
</style>
